import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GraphQLDate: any;
  Upload: any;
};

export type Answer = {
  __typename?: 'Answer';
  _id: Scalars['ID'];
  answerIds: Array<Scalars['ID']>;
  questionId: Scalars['ID'];
  score: Scalars['Int'];
  tableNumber: Scalars['Int'];
};

export type AnswerOption = {
  __typename?: 'AnswerOption';
  _id: Scalars['ID'];
  label: TranslatedLabel;
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveQuestion: Question;
  createUser?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  resetGame: Scalars['Boolean'];
  revealQuestion: Question;
  startGame: Scalars['Boolean'];
  startQuestion: Question;
  submitAnswer: Answer;
};


export type MutationArchiveQuestionArgs = {
  questionId: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  deviceId: Scalars['ID'];
  tableNumber: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  deviceId: Scalars['ID'];
};


export type MutationRevealQuestionArgs = {
  questionId: Scalars['ID'];
};


export type MutationStartQuestionArgs = {
  questionId: Scalars['ID'];
};


export type MutationSubmitAnswerArgs = {
  answerIds: Array<Scalars['ID']>;
  deviceId: Scalars['ID'];
  questionId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  answers: Array<Answer>;
  currentQuestion?: Maybe<Question>;
  hasGameStarted: Scalars['Boolean'];
  questionsInfo: Array<Question>;
  ranking: Array<Scalars['Int']>;
  tableAnswer?: Maybe<Answer>;
  tablesInfo: Array<TableInfo>;
};


export type QueryTableAnswerArgs = {
  questionId: Scalars['ID'];
  tableNumber: Scalars['Int'];
};

export type Question = {
  __typename?: 'Question';
  _id: Scalars['ID'];
  answers: Array<Answer>;
  availableAt?: Maybe<Scalars['GraphQLDate']>;
  correctAnswers: Array<AnswerOption>;
  imageUrl: Scalars['String'];
  question: TranslatedLabel;
  revealedImageUrl?: Maybe<Scalars['String']>;
  state: QuestionState;
  type: QuestionType;
  wrongAnswers: Array<AnswerOption>;
};

export enum QuestionState {
  Archived = 'Archived',
  Available = 'Available',
  Hidden = 'Hidden',
  Revealed = 'Revealed'
}

export enum QuestionType {
  MultipleAnswers = 'MultipleAnswers',
  RevealingImage = 'RevealingImage',
  SingleAnswer = 'SingleAnswer'
}

export type Subscription = {
  __typename?: 'Subscription';
  answerAdded: Answer;
  hasGameStartedUpdated: Scalars['Boolean'];
  questionUpdated: Question;
  rankingUpdated: Array<Scalars['Int']>;
  userAdded: User;
  userDeleted: User;
};

export type TableInfo = {
  __typename?: 'TableInfo';
  userIds: Array<Scalars['ID']>;
};

export type TranslatedLabel = {
  __typename?: 'TranslatedLabel';
  en: Scalars['String'];
  fr: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  deviceId: Scalars['ID'];
  tableNumber: Scalars['Int'];
};

export type AnswerFragment = { __typename?: 'Answer', _id: string, answerIds: Array<string>, questionId: string, score: number, tableNumber: number };

export type QuestionFragment = { __typename?: 'Question', _id: string, availableAt?: any | null, imageUrl: string, state: QuestionState, type: QuestionType, revealedImageUrl?: string | null, question: { __typename?: 'TranslatedLabel', fr: string, en: string }, correctAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }>, wrongAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }> };

export type SubmitAnswerMutationVariables = Exact<{
  questionId: Scalars['ID'];
  answerIds: Array<Scalars['ID']> | Scalars['ID'];
  deviceId: Scalars['ID'];
}>;


export type SubmitAnswerMutation = { __typename?: 'Mutation', submitAnswer: { __typename?: 'Answer', _id: string, answerIds: Array<string>, questionId: string, score: number, tableNumber: number } };

export type OnAnswerAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAnswerAddedSubscription = { __typename?: 'Subscription', answerAdded: { __typename?: 'Answer', _id: string, answerIds: Array<string>, questionId: string, score: number, tableNumber: number } };

export type StartGameMutationVariables = Exact<{ [key: string]: never; }>;


export type StartGameMutation = { __typename?: 'Mutation', startGame: boolean };

export type ResetGameMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetGameMutation = { __typename?: 'Mutation', resetGame: boolean };

export type StartQuestionMutationVariables = Exact<{
  questionId: Scalars['ID'];
}>;


export type StartQuestionMutation = { __typename?: 'Mutation', startQuestion: { __typename?: 'Question', _id: string, availableAt?: any | null, imageUrl: string, state: QuestionState, type: QuestionType, revealedImageUrl?: string | null, question: { __typename?: 'TranslatedLabel', fr: string, en: string }, correctAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }>, wrongAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }> } };

export type RevealQuestionMutationVariables = Exact<{
  questionId: Scalars['ID'];
}>;


export type RevealQuestionMutation = { __typename?: 'Mutation', revealQuestion: { __typename?: 'Question', _id: string, availableAt?: any | null, imageUrl: string, state: QuestionState, type: QuestionType, revealedImageUrl?: string | null, question: { __typename?: 'TranslatedLabel', fr: string, en: string }, correctAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }>, wrongAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }> } };

export type ArchiveQuestionMutationVariables = Exact<{
  questionId: Scalars['ID'];
}>;


export type ArchiveQuestionMutation = { __typename?: 'Mutation', archiveQuestion: { __typename?: 'Question', _id: string, availableAt?: any | null, imageUrl: string, state: QuestionState, type: QuestionType, revealedImageUrl?: string | null, question: { __typename?: 'TranslatedLabel', fr: string, en: string }, correctAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }>, wrongAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }> } };

export type CreateUserMutationVariables = Exact<{
  deviceId: Scalars['ID'];
  tableNumber: Scalars['Int'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: boolean | null };

export type DeleteUserMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: boolean | null };

export type GetAnswersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnswersQuery = { __typename?: 'Query', answers: Array<{ __typename?: 'Answer', _id: string, answerIds: Array<string>, questionId: string, score: number, tableNumber: number }> };

export type GetTableAnswerQueryVariables = Exact<{
  questionId: Scalars['ID'];
  tableNumber: Scalars['Int'];
}>;


export type GetTableAnswerQuery = { __typename?: 'Query', tableAnswer?: { __typename?: 'Answer', _id: string, answerIds: Array<string>, questionId: string, score: number, tableNumber: number } | null };

export type GetRankingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRankingQuery = { __typename?: 'Query', ranking: Array<number> };

export type GetHasGameStartedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHasGameStartedQuery = { __typename?: 'Query', hasGameStarted: boolean };

export type GetCurrentQuestionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentQuestionQuery = { __typename?: 'Query', currentQuestion?: { __typename?: 'Question', _id: string, availableAt?: any | null, imageUrl: string, state: QuestionState, type: QuestionType, revealedImageUrl?: string | null, question: { __typename?: 'TranslatedLabel', fr: string, en: string }, correctAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }>, wrongAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }> } | null };

export type GetQuestionsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionsInfoQuery = { __typename?: 'Query', questionsInfo: Array<{ __typename?: 'Question', _id: string, availableAt?: any | null, imageUrl: string, state: QuestionState, type: QuestionType, revealedImageUrl?: string | null, answers: Array<{ __typename?: 'Answer', _id: string, answerIds: Array<string>, questionId: string, score: number, tableNumber: number }>, question: { __typename?: 'TranslatedLabel', fr: string, en: string }, correctAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }>, wrongAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }> }> };

export type GetTablesInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTablesInfoQuery = { __typename?: 'Query', tablesInfo: Array<{ __typename?: 'TableInfo', userIds: Array<string> }> };

export type OnRankingUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnRankingUpdatedSubscription = { __typename?: 'Subscription', rankingUpdated: Array<number> };

export type OnHasGameStartedUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnHasGameStartedUpdatedSubscription = { __typename?: 'Subscription', hasGameStartedUpdated: boolean };

export type OnQuestionUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnQuestionUpdatedSubscription = { __typename?: 'Subscription', questionUpdated: { __typename?: 'Question', _id: string, availableAt?: any | null, imageUrl: string, state: QuestionState, type: QuestionType, revealedImageUrl?: string | null, question: { __typename?: 'TranslatedLabel', fr: string, en: string }, correctAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }>, wrongAnswers: Array<{ __typename?: 'AnswerOption', _id: string, label: { __typename?: 'TranslatedLabel', fr: string, en: string } }> } };

export type OnUserAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUserAddedSubscription = { __typename?: 'Subscription', userAdded: { __typename?: 'User', _id: string, deviceId: string, tableNumber: number } };

export type OnUserDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUserDeletedSubscription = { __typename?: 'Subscription', userDeleted: { __typename?: 'User', _id: string, tableNumber: number } };

export const AnswerFragmentDoc = gql`
    fragment Answer on Answer {
  _id
  answerIds
  questionId
  score
  tableNumber
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  _id
  availableAt
  question {
    fr
    en
  }
  correctAnswers {
    _id
    label {
      fr
      en
    }
  }
  wrongAnswers {
    _id
    label {
      fr
      en
    }
  }
  imageUrl
  state
  type
  revealedImageUrl
}
    `;
export const SubmitAnswerDocument = gql`
    mutation SubmitAnswer($questionId: ID!, $answerIds: [ID!]!, $deviceId: ID!) {
  submitAnswer(
    questionId: $questionId
    answerIds: $answerIds
    deviceId: $deviceId
  ) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;
export type SubmitAnswerMutationFn = Apollo.MutationFunction<SubmitAnswerMutation, SubmitAnswerMutationVariables>;

/**
 * __useSubmitAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswerMutation, { data, loading, error }] = useSubmitAnswerMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      answerIds: // value for 'answerIds'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useSubmitAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAnswerMutation, SubmitAnswerMutationVariables>(SubmitAnswerDocument, options);
      }
export type SubmitAnswerMutationHookResult = ReturnType<typeof useSubmitAnswerMutation>;
export type SubmitAnswerMutationResult = Apollo.MutationResult<SubmitAnswerMutation>;
export type SubmitAnswerMutationOptions = Apollo.BaseMutationOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>;
export const OnAnswerAddedDocument = gql`
    subscription OnAnswerAdded {
  answerAdded {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;

/**
 * __useOnAnswerAddedSubscription__
 *
 * To run a query within a React component, call `useOnAnswerAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnswerAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnswerAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnswerAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAnswerAddedSubscription, OnAnswerAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAnswerAddedSubscription, OnAnswerAddedSubscriptionVariables>(OnAnswerAddedDocument, options);
      }
export type OnAnswerAddedSubscriptionHookResult = ReturnType<typeof useOnAnswerAddedSubscription>;
export type OnAnswerAddedSubscriptionResult = Apollo.SubscriptionResult<OnAnswerAddedSubscription>;
export const StartGameDocument = gql`
    mutation StartGame {
  startGame
}
    `;
export type StartGameMutationFn = Apollo.MutationFunction<StartGameMutation, StartGameMutationVariables>;

/**
 * __useStartGameMutation__
 *
 * To run a mutation, you first call `useStartGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startGameMutation, { data, loading, error }] = useStartGameMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartGameMutation(baseOptions?: Apollo.MutationHookOptions<StartGameMutation, StartGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartGameMutation, StartGameMutationVariables>(StartGameDocument, options);
      }
export type StartGameMutationHookResult = ReturnType<typeof useStartGameMutation>;
export type StartGameMutationResult = Apollo.MutationResult<StartGameMutation>;
export type StartGameMutationOptions = Apollo.BaseMutationOptions<StartGameMutation, StartGameMutationVariables>;
export const ResetGameDocument = gql`
    mutation ResetGame {
  resetGame
}
    `;
export type ResetGameMutationFn = Apollo.MutationFunction<ResetGameMutation, ResetGameMutationVariables>;

/**
 * __useResetGameMutation__
 *
 * To run a mutation, you first call `useResetGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetGameMutation, { data, loading, error }] = useResetGameMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetGameMutation(baseOptions?: Apollo.MutationHookOptions<ResetGameMutation, ResetGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetGameMutation, ResetGameMutationVariables>(ResetGameDocument, options);
      }
export type ResetGameMutationHookResult = ReturnType<typeof useResetGameMutation>;
export type ResetGameMutationResult = Apollo.MutationResult<ResetGameMutation>;
export type ResetGameMutationOptions = Apollo.BaseMutationOptions<ResetGameMutation, ResetGameMutationVariables>;
export const StartQuestionDocument = gql`
    mutation StartQuestion($questionId: ID!) {
  startQuestion(questionId: $questionId) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type StartQuestionMutationFn = Apollo.MutationFunction<StartQuestionMutation, StartQuestionMutationVariables>;

/**
 * __useStartQuestionMutation__
 *
 * To run a mutation, you first call `useStartQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startQuestionMutation, { data, loading, error }] = useStartQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useStartQuestionMutation(baseOptions?: Apollo.MutationHookOptions<StartQuestionMutation, StartQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartQuestionMutation, StartQuestionMutationVariables>(StartQuestionDocument, options);
      }
export type StartQuestionMutationHookResult = ReturnType<typeof useStartQuestionMutation>;
export type StartQuestionMutationResult = Apollo.MutationResult<StartQuestionMutation>;
export type StartQuestionMutationOptions = Apollo.BaseMutationOptions<StartQuestionMutation, StartQuestionMutationVariables>;
export const RevealQuestionDocument = gql`
    mutation RevealQuestion($questionId: ID!) {
  revealQuestion(questionId: $questionId) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type RevealQuestionMutationFn = Apollo.MutationFunction<RevealQuestionMutation, RevealQuestionMutationVariables>;

/**
 * __useRevealQuestionMutation__
 *
 * To run a mutation, you first call `useRevealQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevealQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revealQuestionMutation, { data, loading, error }] = useRevealQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useRevealQuestionMutation(baseOptions?: Apollo.MutationHookOptions<RevealQuestionMutation, RevealQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevealQuestionMutation, RevealQuestionMutationVariables>(RevealQuestionDocument, options);
      }
export type RevealQuestionMutationHookResult = ReturnType<typeof useRevealQuestionMutation>;
export type RevealQuestionMutationResult = Apollo.MutationResult<RevealQuestionMutation>;
export type RevealQuestionMutationOptions = Apollo.BaseMutationOptions<RevealQuestionMutation, RevealQuestionMutationVariables>;
export const ArchiveQuestionDocument = gql`
    mutation ArchiveQuestion($questionId: ID!) {
  archiveQuestion(questionId: $questionId) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type ArchiveQuestionMutationFn = Apollo.MutationFunction<ArchiveQuestionMutation, ArchiveQuestionMutationVariables>;

/**
 * __useArchiveQuestionMutation__
 *
 * To run a mutation, you first call `useArchiveQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveQuestionMutation, { data, loading, error }] = useArchiveQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useArchiveQuestionMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveQuestionMutation, ArchiveQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveQuestionMutation, ArchiveQuestionMutationVariables>(ArchiveQuestionDocument, options);
      }
export type ArchiveQuestionMutationHookResult = ReturnType<typeof useArchiveQuestionMutation>;
export type ArchiveQuestionMutationResult = Apollo.MutationResult<ArchiveQuestionMutation>;
export type ArchiveQuestionMutationOptions = Apollo.BaseMutationOptions<ArchiveQuestionMutation, ArchiveQuestionMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($deviceId: ID!, $tableNumber: Int!) {
  createUser(deviceId: $deviceId, tableNumber: $tableNumber)
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      tableNumber: // value for 'tableNumber'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deviceId: ID!) {
  deleteUser(deviceId: $deviceId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetAnswersDocument = gql`
    query GetAnswers {
  answers {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;

/**
 * __useGetAnswersQuery__
 *
 * To run a query within a React component, call `useGetAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnswersQuery(baseOptions?: Apollo.QueryHookOptions<GetAnswersQuery, GetAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnswersQuery, GetAnswersQueryVariables>(GetAnswersDocument, options);
      }
export function useGetAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnswersQuery, GetAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnswersQuery, GetAnswersQueryVariables>(GetAnswersDocument, options);
        }
export type GetAnswersQueryHookResult = ReturnType<typeof useGetAnswersQuery>;
export type GetAnswersLazyQueryHookResult = ReturnType<typeof useGetAnswersLazyQuery>;
export type GetAnswersQueryResult = Apollo.QueryResult<GetAnswersQuery, GetAnswersQueryVariables>;
export const GetTableAnswerDocument = gql`
    query GetTableAnswer($questionId: ID!, $tableNumber: Int!) {
  tableAnswer(questionId: $questionId, tableNumber: $tableNumber) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;

/**
 * __useGetTableAnswerQuery__
 *
 * To run a query within a React component, call `useGetTableAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableAnswerQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      tableNumber: // value for 'tableNumber'
 *   },
 * });
 */
export function useGetTableAnswerQuery(baseOptions: Apollo.QueryHookOptions<GetTableAnswerQuery, GetTableAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTableAnswerQuery, GetTableAnswerQueryVariables>(GetTableAnswerDocument, options);
      }
export function useGetTableAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableAnswerQuery, GetTableAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTableAnswerQuery, GetTableAnswerQueryVariables>(GetTableAnswerDocument, options);
        }
export type GetTableAnswerQueryHookResult = ReturnType<typeof useGetTableAnswerQuery>;
export type GetTableAnswerLazyQueryHookResult = ReturnType<typeof useGetTableAnswerLazyQuery>;
export type GetTableAnswerQueryResult = Apollo.QueryResult<GetTableAnswerQuery, GetTableAnswerQueryVariables>;
export const GetRankingDocument = gql`
    query GetRanking {
  ranking
}
    `;

/**
 * __useGetRankingQuery__
 *
 * To run a query within a React component, call `useGetRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRankingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRankingQuery(baseOptions?: Apollo.QueryHookOptions<GetRankingQuery, GetRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRankingQuery, GetRankingQueryVariables>(GetRankingDocument, options);
      }
export function useGetRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRankingQuery, GetRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRankingQuery, GetRankingQueryVariables>(GetRankingDocument, options);
        }
export type GetRankingQueryHookResult = ReturnType<typeof useGetRankingQuery>;
export type GetRankingLazyQueryHookResult = ReturnType<typeof useGetRankingLazyQuery>;
export type GetRankingQueryResult = Apollo.QueryResult<GetRankingQuery, GetRankingQueryVariables>;
export const GetHasGameStartedDocument = gql`
    query GetHasGameStarted {
  hasGameStarted
}
    `;

/**
 * __useGetHasGameStartedQuery__
 *
 * To run a query within a React component, call `useGetHasGameStartedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHasGameStartedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHasGameStartedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHasGameStartedQuery(baseOptions?: Apollo.QueryHookOptions<GetHasGameStartedQuery, GetHasGameStartedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHasGameStartedQuery, GetHasGameStartedQueryVariables>(GetHasGameStartedDocument, options);
      }
export function useGetHasGameStartedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHasGameStartedQuery, GetHasGameStartedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHasGameStartedQuery, GetHasGameStartedQueryVariables>(GetHasGameStartedDocument, options);
        }
export type GetHasGameStartedQueryHookResult = ReturnType<typeof useGetHasGameStartedQuery>;
export type GetHasGameStartedLazyQueryHookResult = ReturnType<typeof useGetHasGameStartedLazyQuery>;
export type GetHasGameStartedQueryResult = Apollo.QueryResult<GetHasGameStartedQuery, GetHasGameStartedQueryVariables>;
export const GetCurrentQuestionDocument = gql`
    query GetCurrentQuestion {
  currentQuestion {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useGetCurrentQuestionQuery__
 *
 * To run a query within a React component, call `useGetCurrentQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentQuestionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentQuestionQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>(GetCurrentQuestionDocument, options);
      }
export function useGetCurrentQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>(GetCurrentQuestionDocument, options);
        }
export type GetCurrentQuestionQueryHookResult = ReturnType<typeof useGetCurrentQuestionQuery>;
export type GetCurrentQuestionLazyQueryHookResult = ReturnType<typeof useGetCurrentQuestionLazyQuery>;
export type GetCurrentQuestionQueryResult = Apollo.QueryResult<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>;
export const GetQuestionsInfoDocument = gql`
    query GetQuestionsInfo {
  questionsInfo {
    ...Question
    answers {
      ...Answer
    }
  }
}
    ${QuestionFragmentDoc}
${AnswerFragmentDoc}`;

/**
 * __useGetQuestionsInfoQuery__
 *
 * To run a query within a React component, call `useGetQuestionsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionsInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionsInfoQuery, GetQuestionsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsInfoQuery, GetQuestionsInfoQueryVariables>(GetQuestionsInfoDocument, options);
      }
export function useGetQuestionsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsInfoQuery, GetQuestionsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsInfoQuery, GetQuestionsInfoQueryVariables>(GetQuestionsInfoDocument, options);
        }
export type GetQuestionsInfoQueryHookResult = ReturnType<typeof useGetQuestionsInfoQuery>;
export type GetQuestionsInfoLazyQueryHookResult = ReturnType<typeof useGetQuestionsInfoLazyQuery>;
export type GetQuestionsInfoQueryResult = Apollo.QueryResult<GetQuestionsInfoQuery, GetQuestionsInfoQueryVariables>;
export const GetTablesInfoDocument = gql`
    query GetTablesInfo {
  tablesInfo {
    userIds
  }
}
    `;

/**
 * __useGetTablesInfoQuery__
 *
 * To run a query within a React component, call `useGetTablesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTablesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTablesInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTablesInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetTablesInfoQuery, GetTablesInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTablesInfoQuery, GetTablesInfoQueryVariables>(GetTablesInfoDocument, options);
      }
export function useGetTablesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTablesInfoQuery, GetTablesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTablesInfoQuery, GetTablesInfoQueryVariables>(GetTablesInfoDocument, options);
        }
export type GetTablesInfoQueryHookResult = ReturnType<typeof useGetTablesInfoQuery>;
export type GetTablesInfoLazyQueryHookResult = ReturnType<typeof useGetTablesInfoLazyQuery>;
export type GetTablesInfoQueryResult = Apollo.QueryResult<GetTablesInfoQuery, GetTablesInfoQueryVariables>;
export const OnRankingUpdatedDocument = gql`
    subscription OnRankingUpdated {
  rankingUpdated
}
    `;

/**
 * __useOnRankingUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnRankingUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRankingUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRankingUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnRankingUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnRankingUpdatedSubscription, OnRankingUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnRankingUpdatedSubscription, OnRankingUpdatedSubscriptionVariables>(OnRankingUpdatedDocument, options);
      }
export type OnRankingUpdatedSubscriptionHookResult = ReturnType<typeof useOnRankingUpdatedSubscription>;
export type OnRankingUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnRankingUpdatedSubscription>;
export const OnHasGameStartedUpdatedDocument = gql`
    subscription OnHasGameStartedUpdated {
  hasGameStartedUpdated
}
    `;

/**
 * __useOnHasGameStartedUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnHasGameStartedUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnHasGameStartedUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnHasGameStartedUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnHasGameStartedUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnHasGameStartedUpdatedSubscription, OnHasGameStartedUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnHasGameStartedUpdatedSubscription, OnHasGameStartedUpdatedSubscriptionVariables>(OnHasGameStartedUpdatedDocument, options);
      }
export type OnHasGameStartedUpdatedSubscriptionHookResult = ReturnType<typeof useOnHasGameStartedUpdatedSubscription>;
export type OnHasGameStartedUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnHasGameStartedUpdatedSubscription>;
export const OnQuestionUpdatedDocument = gql`
    subscription OnQuestionUpdated {
  questionUpdated {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useOnQuestionUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnQuestionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnQuestionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnQuestionUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnQuestionUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnQuestionUpdatedSubscription, OnQuestionUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnQuestionUpdatedSubscription, OnQuestionUpdatedSubscriptionVariables>(OnQuestionUpdatedDocument, options);
      }
export type OnQuestionUpdatedSubscriptionHookResult = ReturnType<typeof useOnQuestionUpdatedSubscription>;
export type OnQuestionUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnQuestionUpdatedSubscription>;
export const OnUserAddedDocument = gql`
    subscription OnUserAdded {
  userAdded {
    _id
    deviceId
    tableNumber
  }
}
    `;

/**
 * __useOnUserAddedSubscription__
 *
 * To run a query within a React component, call `useOnUserAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUserAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUserAddedSubscription, OnUserAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUserAddedSubscription, OnUserAddedSubscriptionVariables>(OnUserAddedDocument, options);
      }
export type OnUserAddedSubscriptionHookResult = ReturnType<typeof useOnUserAddedSubscription>;
export type OnUserAddedSubscriptionResult = Apollo.SubscriptionResult<OnUserAddedSubscription>;
export const OnUserDeletedDocument = gql`
    subscription OnUserDeleted {
  userDeleted {
    _id
    tableNumber
  }
}
    `;

/**
 * __useOnUserDeletedSubscription__
 *
 * To run a query within a React component, call `useOnUserDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUserDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUserDeletedSubscription, OnUserDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUserDeletedSubscription, OnUserDeletedSubscriptionVariables>(OnUserDeletedDocument, options);
      }
export type OnUserDeletedSubscriptionHookResult = ReturnType<typeof useOnUserDeletedSubscription>;
export type OnUserDeletedSubscriptionResult = Apollo.SubscriptionResult<OnUserDeletedSubscription>;