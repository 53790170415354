import "./i18n/config";
import "./App.css";

import styled from "styled-components";
import { ApolloProvider } from "@apollo/client";
import { client } from "./data/config.connections";
import { TablesInfo } from "./components/TablesInfo";
import { QuestionsInfo } from "./components/QuestionsInfo";
import { AdminActions } from "./components/AdminActions";
import { Presentation } from "./components/Presentation";
import { RankingInfo } from "./components/RankingInfo";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import QuizIcon from "@mui/icons-material/Quiz";
import SettingsIcon from "@mui/icons-material/Settings";
import TableBarIcon from "@mui/icons-material/TableBar";
import TextsmsIcon from "@mui/icons-material/Textsms";

function Admin() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Container>
          <NavBar>
            <IconContainer href="#presentation">
              <TextsmsIcon style={{ width: 50, aspectRatio: 1 }} />
            </IconContainer>
            <IconContainer href="#tables-info">
              <TableBarIcon style={{ width: 50, aspectRatio: 1 }} />
            </IconContainer>
            <IconContainer href="#questions-info">
              <QuizIcon style={{ width: 50, aspectRatio: 1 }} />
            </IconContainer>
            <IconContainer href="#ranking-info">
              <FormatListNumberedIcon style={{ width: 50, aspectRatio: 1 }} />
            </IconContainer>
            <IconContainer href="#admin-actions">
              <SettingsIcon style={{ width: 50, aspectRatio: 1 }} />
            </IconContainer>
          </NavBar>
          <ContentOuterContainer>
            <ContentInnerContainer>
              <div id="presentation">
                <Presentation />
              </div>
              <div id="tables-info">
                <TablesInfo />
              </div>
              <div id="questions-info">
                <QuestionsInfo />
              </div>
              <div id="ranking-info">
                <RankingInfo />
              </div>
              <div id="admin-actions">
                <AdminActions />
              </div>
            </ContentInnerContainer>
          </ContentOuterContainer>
        </Container>
      </div>
    </ApolloProvider>
  );
}

export default Admin;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  background-color: #eeeeee;
`;

const IconContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 10px;
  aspect-ratio: 1;
  cursor: pointer;
  color: black;
`;

const ContentOuterContainer = styled.div`
  overflow: hidden;
  flex: 1;
`;

const ContentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 30px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
`;
