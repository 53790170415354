import { gql } from "@apollo/client";
import { ANSWER_FRAGMENT } from "../fragments/answer";

export const GET_ANSWERS_QUERY = gql`
  query GetAnswers {
    answers {
      ...Answer
    }
  }
  ${ANSWER_FRAGMENT}
`;

export const GET_TABLE_ANSWER_QUERY = gql`
  query GetTableAnswer($questionId: ID!, $tableNumber: Int!) {
    tableAnswer(questionId: $questionId, tableNumber: $tableNumber) {
      ...Answer
    }
  }
  ${ANSWER_FRAGMENT}
`;

export const GET_RANKING_QUERY = gql`
  query GetRanking {
    ranking
  }
`;
