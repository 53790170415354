import styled from "styled-components";
import { AnswerFragment } from "../data/generated";
import { useTranslate } from "../utils/translate";

import AngryMary from "../assets/angry-mary.png";
import GhostIsma from "../assets/ghost-isma.png";
import CoolMary from "../assets/cool-mary.png";
import HappyMary from "../assets/happy-mary.png";
import HappyIsma from "../assets/happy-isma.png";
import { useTranslation } from "react-i18next";

function getRandomAngrySource() {
  const rand = Math.random();

  return rand > 0.5 ? AngryMary : GhostIsma;
}

function getRandomHappySource() {
  const rand = Math.random();

  return rand > 0.5 ? HappyIsma : rand > 0.25 ? CoolMary : HappyMary;
}

export const RevealedOverlay = ({
  correctAnswers,
  revealedImageUrl,
  tableAnswer,
}: {
  correctAnswers: { _id: string; label: { en: string; fr: string } }[];
  revealedImageUrl?: string | null;
  tableAnswer?: AnswerFragment | null;
}) => {
  const { t } = useTranslation();
  const translate = useTranslate();

  const isCorrect =
    tableAnswer &&
    tableAnswer.answerIds.length === correctAnswers.length &&
    tableAnswer.answerIds.every((answerId) =>
      correctAnswers.some((answer) => answer._id === answerId)
    );
  const isPartiallyCorrect =
    tableAnswer &&
    tableAnswer.answerIds.some((answerId) =>
      correctAnswers.some((answer) => answer._id === answerId)
    );

  return (
    <Container id="revealed-overlay">
      <InnerContainer>
        <Row>
          <img
            src={
              isCorrect || isPartiallyCorrect
                ? getRandomHappySource()
                : getRandomAngrySource()
            }
            width="45%"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 15,
              gap: 10,
            }}
          >
            <LargeLabel>
              {!tableAnswer
                ? t("times_up")
                : isCorrect
                  ? t("congrats")
                  : isPartiallyCorrect
                    ? t("almost")
                    : t("not_at_all")}
            </LargeLabel>
            <Label>
              {t("expected_answers", { count: correctAnswers.length })}
              {correctAnswers.map(({ label }) => translate(label)).join(", ")}
            </Label>
          </div>
        </Row>
        <ScoreContainer score={tableAnswer?.score ?? 0}>
          +{tableAnswer?.score ?? 0} point
          {(tableAnswer?.score ?? 0) > 1 ? "s" : ""}
        </ScoreContainer>
        {revealedImageUrl ? (
          <ImageContainer>
            <img
              src={revealedImageUrl}
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
            />
          </ImageContainer>
        ) : null}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  z-index: 1;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: 800;
  padding: 15px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const ScoreContainer = styled.div<{ score: number }>`
  display: flex;
  justify-content: center;
  background-color: ${({ score }) =>
    score === 0 ? "red" : score > 66 ? "limegreen" : "orange"};
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  font-size: 36px;
`;

const LargeLabel = styled.div`
  font-size: 28px;
  font-weight: 800;
`;

const Label = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

const ImageContainer = styled.div`
  width: 70%;
  aspect-ratio: 0.8;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 15px;
`;
