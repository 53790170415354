import i18n from "i18next";
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const supportedLngs = {
  en: "English",
  fr: "Français",
};

i18n
  // Add React bindings as a plugin.
  .use(initReactI18next)
  .use(LanguageDetector)
  // Initialize the i18next instance.
  .init({
    // Config options

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but feel free to use
    // whichever locale you want.
    // lng: "fr",

    detection: {
      // Options pour le détecteur de langue
      order: ["localStorage", "cookie", "querystring", "navigator"],
      caches: ["localStorage", "cookie"], // Sauvegarder la langue dans localStorage et cookie
    },

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here.
    fallbackLng: "en",

    supportedLngs: Object.keys(supportedLngs),

    // Enables useful output in the browser’s
    // dev console.
    debug: true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      // English
      en: {
        // `translation` is the default namespace.
        // More details about namespaces shortly.
        translation: {
          hello_world: "Hello, World!",
          ranking: "Ranking",
          select_table: "Select your table",
          validate: "Submit",
          waiting_for_question: "Waiting for a question",
          congrats: "Congrats!",
          almost: "Almost",
          not_at_all: "Nope...",
          expected_answers_one: "The expected answer was: ",
          expected_answers_other: "The expected answers were: ",
          discuss: "Discuss before answering!",
          be_patient: "Be patient, the wait is almost over!",
          times_up: "Time's up!",
        },
      },
      // French
      fr: {
        translation: {
          hello_world: "Bonjour le monde !",
          ranking: "Classement",
          select_table: "Sélectionnez votre table",
          validate: "Valider",
          waiting_for_question: "En attente d'une question",
          congrats: "Bravo !",
          almost: "Presque",
          not_at_all: "Pas du tout...",
          expected_answers_one: "Il fallait répondre\u00a0: ",
          expected_answers_other: "Il fallait répondre : ",
          expected_answer: "Il fallait répondre : ",
          discuss: "Discutez avant de répondre\u00a0!",
          be_patient: "Encore un peu de patience\u00a0!",
          times_up: "Temps écoulé\u00a0!",
        },
      },
    },
  });

export default i18n;
