import styled from "styled-components";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { Title } from "./Title";

export const Presentation = () => {
  return (
    <Container>
      <TitleContainer>
        <TextsmsIcon style={{ width: 24, height: 24 }} />
        <Title>Présentation</Title>
      </TitleContainer>
      <TablesContainer>
        <ul>
          <li>
            Bonjour tout le monde, pour ceux qui ne me connaissent pas, je me
            présente, je m'appelle Maxence, et j'ai été sollicité pour animer un
            petit quiz en attendant le prochain plat.
          </li>
          <li>
            Bon, il faut savoir que pour faire ce quiz, j'ai développé un petit
            site sans prétention, et que s'il y a des bugs voire même si rien ne
            marche, je n'ai absolument pas de solution de secours.
          </li>
          <li>
            Pour commencer, rendez-vous sur ismarie.fr <i>(épeler)</i>. Si vous
            n'y arrivez pas, hurlez "On est nuls, on est nuls !" et je viendrai
            vous aider.
          </li>
          <li>
            Désignez un "capitaine" par table qui répondra aux questions (vous
            ne pourrez donner qu'une réponse par table)
          </li>
          <li>
            Choisissez votre numéro de table, à l'aide du numéro que vous
            trouverez sur votre table.
          </li>
          <li>
            Pour chaque question, les réponses seront bloquées pendant 15
            secondes pour que vous discutiez avec les personnes à votre table de
            la ou des réponse(s) à choisir.
          </li>
          <li>
            Plus vous donnez une réponse rapidement, plus vous marquez de points
          </li>
          <li>
            Pour certaines questions, il peut y avoir plusieurs réponses. Pour
            avoir tous les points, il faut donner toutes les bonnes réponses,
            mais vous perdrez des points si vous sélectionnez de mauvaises
            réponses.
          </li>
        </ul>
      </TablesContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;
