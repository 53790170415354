import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useUserContext } from "../contexts/UserContext";
import { useCreateUserMutation } from "../data/generated";
import { v4 as uuidv4 } from "uuid";
import { NUMBER_OF_TABLES } from "../constants/tables";
import { Title } from "./Title";

import TableBarIcon from "@mui/icons-material/TableBar";

export const TablePicker = () => {
  const { t } = useTranslation();

  const { setUser } = useUserContext();

  const [createUserMutation, { loading }] = useCreateUserMutation();

  const tableIndices = new Array(NUMBER_OF_TABLES).fill(0);

  async function selectTable(tableNumber: number) {
    const deviceId = uuidv4();

    await createUserMutation({ variables: { deviceId, tableNumber } });

    setUser({ deviceId, tableNumber });
  }

  return (
    <Container>
      <TitleContainer>
        <TableBarIcon style={{ width: 24, height: 24 }} />
        <Title>{t("select_table")}</Title>
      </TitleContainer>
      <TablesContainer>
        {tableIndices.map((_, index) => {
          return (
            <TableContainer
              key={index}
              onClick={() => (!loading ? selectTable(index) : null)}
            >
              <TableNumber>{index + 1}</TableNumber>
            </TableContainer>
          );
        })}
      </TablesContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const TableContainer = styled.div`
  width: calc((100% - 4 * 5px) / 5);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  border-radius: 5px;
`;

const TableNumber = styled.span`
  color: white;
`;
