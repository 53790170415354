import { useTranslation } from "react-i18next";
import { Question } from "./Question";
import {
  GetCurrentQuestionQuery,
  GetTableAnswerQuery,
  QuestionState,
  useDeleteUserMutation,
  useGetCurrentQuestionQuery,
  useOnQuestionUpdatedSubscription,
  useSubmitAnswerMutation,
} from "../data/generated";
import { useApolloClient } from "@apollo/client";
import { GET_CURRENT_QUESTION_QUERY } from "../data/queries/question";
import { useUser, useUserContext } from "../contexts/UserContext";
import styled from "styled-components";
import { Ranking } from "./Ranking";
import { useEffect } from "react";
import { GET_TABLE_ANSWER_QUERY } from "../data/queries/answer";

export const Game = () => {
  const client = useApolloClient();

  const { i18n, t } = useTranslation();

  const { data, refetch: refetchQuestion } = useGetCurrentQuestionQuery();

  const { setUser } = useUserContext();
  const user = useUser();

  const [submitAnswerMutation] = useSubmitAnswerMutation();
  const [deleteUserMutation] = useDeleteUserMutation();

  const currentQuestion =
    data?.currentQuestion &&
    [QuestionState.Available, QuestionState.Revealed].includes(
      data.currentQuestion.state
    )
      ? data.currentQuestion
      : null;

  const hasQuestion = !!currentQuestion;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hasQuestion]);

  useEffect(() => {
    i18n.on("languageChanged", refetchQuestion);

    return () => {
      i18n.off("languageChanged", refetchQuestion);
    };
  }, [i18n.language]);

  useOnQuestionUpdatedSubscription({
    onData: ({ data: { data } }) => {
      if (!data) {
        return;
      }

      const { questionUpdated } = data;

      if (questionUpdated.state === QuestionState.Available) {
        client.writeQuery<GetCurrentQuestionQuery>({
          query: GET_CURRENT_QUESTION_QUERY,
          data: { currentQuestion: questionUpdated },
        });
      }
    },
  });

  async function deleteUser() {
    setUser(null);
    deleteUserMutation({ variables: { deviceId: user.deviceId } });
  }

  async function onSubmit(answerIds: string[]) {
    if (!user || !data?.currentQuestion) {
      return false;
    }

    const { deviceId } = user;

    const { data: resultData } = await submitAnswerMutation({
      variables: { deviceId, questionId: data.currentQuestion._id, answerIds },
    });

    if (resultData?.submitAnswer) {
      client.writeQuery<GetTableAnswerQuery>({
        query: GET_TABLE_ANSWER_QUERY,
        variables: {
          questionId: data.currentQuestion._id,
          tableNumber: resultData.submitAnswer.tableNumber,
        },
        data: { tableAnswer: resultData.submitAnswer },
      });
    }

    return !!resultData && !!resultData.submitAnswer;
  }

  return currentQuestion ? (
    <Question question={currentQuestion} onSubmit={onSubmit} />
  ) : (
    <ContentContainer>
      <Table onClick={deleteUser}>Table n°{user.tableNumber + 1}</Table>
      <Label>{t("waiting_for_question")}</Label>
      <RankingContainer>
        <Ranking />
      </RankingContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  flex: 1;
`;

const Table = styled.span`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
`;

const Label = styled.span`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
`;

const RankingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
