import { gql } from "@apollo/client";
import { QUESTION_FRAGMENT } from "../fragments/question";
import { ANSWER_FRAGMENT } from "../fragments/answer";

export const GET_CURRENT_QUESTION_QUERY = gql`
  query GetCurrentQuestion {
    currentQuestion {
      ...Question
    }
  }
  ${QUESTION_FRAGMENT}
`;

export const GET_QUESTIONS_INFO_QUERY = gql`
  query GetQuestionsInfo {
    questionsInfo {
      ...Question
      answers {
        ...Answer
      }
    }
  }
  ${QUESTION_FRAGMENT}
  ${ANSWER_FRAGMENT}
`;
