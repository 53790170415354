import { gql } from "@apollo/client";

export const QUESTION_FRAGMENT = gql`
  fragment Question on Question {
    _id
    availableAt
    question {
      fr
      en
    }
    correctAnswers {
      _id
      label {
        fr
        en
      }
    }
    wrongAnswers {
      _id
      label {
        fr
        en
      }
    }
    imageUrl
    state
    type
    revealedImageUrl
  }
`;
