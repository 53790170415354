import LocaleSwitcher from "../i18n/LocaleSwitcher";
import { TablePicker } from "../components/TablePicker";
import { Game } from "../components/Game";
import { useUserContext } from "../contexts/UserContext";
import styled from "styled-components";
import {
  GetHasGameStartedQuery,
  useGetHasGameStartedQuery,
  useOnHasGameStartedUpdatedSubscription,
} from "../data/generated";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { GET_HAS_GAME_STARTED_QUERY } from "../data/queries/gameParameters";

export const GameWrapper = () => {
  const { user } = useUserContext();

  const { t } = useTranslation();

  const client = useApolloClient();

  const { data } = useGetHasGameStartedQuery();

  useOnHasGameStartedUpdatedSubscription({
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) {
        return;
      }

      const { hasGameStartedUpdated } = data;

      client.writeQuery<GetHasGameStartedQuery>({
        query: GET_HAS_GAME_STARTED_QUERY,
        data: { hasGameStarted: hasGameStartedUpdated },
      });
    },
  });

  if (!data) {
    return <div>Chargement...</div>;
  }

  if (!data.hasGameStarted) {
    return (
      <Container style={{ backgroundColor: "rgb(35, 39, 47)" }}>
        <WaitingContainer>
          <ImageContainer>
            <img
              src="https://qwest-assets.s3.eu-west-3.amazonaws.com/ismarie-to-delete/WhatsApp+Image+2024-08-31+at+12.03.28.jpeg"
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
            />
          </ImageContainer>
          <LabelContainer>
            <Label>{t("be_patient")}</Label>
          </LabelContainer>
          <LocaleSwitcher />
        </WaitingContainer>
      </Container>
    );
  }

  return (
    <Container>
      <GameContainer>{user ? <Game /> : <TablePicker />}</GameContainer>
      <LocaleSwitcher />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const WaitingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  background-color: rgb(35, 39, 47);
  padding: 20px;
`;

const ImageContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 3/2;
`;

const LabelContainer = styled.div`
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Label = styled.span`
  font-size: 20px;
  color: white;
  text-align: center;
`;

const GameContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
`;
