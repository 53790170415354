import styled from "styled-components";

export const Button = ({
  label,
  onClick,
  isDisabled,
  isDangerous = false,
}: {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
  isDangerous?: boolean;
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={isDisabled}
      isDangerous={isDangerous}
    >
      {label}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ isDangerous: boolean }>`
  background-color: ${({ isDangerous }) =>
    isDangerous ? "red" : "cornflowerblue"};
  outline: none;
  border: none;
  padding: 10px 15px;
  color: white;
  font-weight: bold;
  border-radius: 5px;

  &:disabled {
    opacity: 0.5;
  }
`;
