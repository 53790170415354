import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { createClient } from "graphql-ws";
import i18n from "../i18n/config";

const uri = process.env.REACT_APP_API_URL || `http://localhost:4000/graphql`;

const httpLink = createHttpLink({
  uri,
});

const localSubscriptionsUri = `ws://localhost:4000/subscriptions`;

const subscriptionClient = createClient({
  url: process.env.REACT_APP_SUBSCRIPTION_URL || localSubscriptionsUri,
  shouldRetry: () => true, // client should not throw if server restarts
});

const wsLink = new GraphQLWsLink(subscriptionClient);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Accept-Language": i18n.language, // Ajoute l'en-tête Accept-Language
    },
  };
});

const typePolicies = {
  GamePlayer: { keyFields: ["_id", "gameId"] },
};

// Initialize Apollo Client
export const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache({ typePolicies }),
});
