import styled from "styled-components";
import { NUMBER_OF_TABLES } from "../constants/tables";
import {
  GetTablesInfoQuery,
  useGetTablesInfoQuery,
  useOnUserAddedSubscription,
  useOnUserDeletedSubscription,
} from "../data/generated";
import TableBarIcon from "@mui/icons-material/TableBar";
import { useApolloClient } from "@apollo/client";
import { GET_TABLES_INFO_QUERY } from "../data/queries/table";
import { Title } from "./Title";

export const TablesInfo = () => {
  const { data } = useGetTablesInfoQuery();

  const client = useApolloClient();

  useOnUserAddedSubscription({
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) {
        return;
      }

      const { userAdded } = data;

      const cachedQuery = client.readQuery<GetTablesInfoQuery>({
        query: GET_TABLES_INFO_QUERY,
      });

      if (!cachedQuery) {
        return;
      }

      const updatedTablesInfo = cachedQuery.tablesInfo.map(
        (table, tableIndex) => {
          if (tableIndex !== userAdded.tableNumber) {
            return table;
          }
          return { userIds: [...table.userIds, userAdded._id] };
        }
      );

      client.writeQuery<GetTablesInfoQuery>({
        query: GET_TABLES_INFO_QUERY,
        data: { tablesInfo: updatedTablesInfo },
      });
    },
  });

  useOnUserDeletedSubscription({
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) {
        return;
      }

      const { userDeleted } = data;

      const cachedQuery = client.readQuery<GetTablesInfoQuery>({
        query: GET_TABLES_INFO_QUERY,
      });

      if (!cachedQuery) {
        return;
      }

      console.log({ userDeleted });

      const updatedTablesInfo = cachedQuery.tablesInfo.map(
        (table, tableIndex) => {
          if (tableIndex !== userDeleted.tableNumber) {
            return table;
          }
          return {
            userIds: table.userIds.filter(
              (userId) => userId !== userDeleted._id
            ),
          };
        }
      );

      client.writeQuery<GetTablesInfoQuery>({
        query: GET_TABLES_INFO_QUERY,
        data: { tablesInfo: updatedTablesInfo },
      });
    },
  });

  if (!data) {
    return null;
  }

  const { tablesInfo } = data;

  const tablesArray = new Array(NUMBER_OF_TABLES).fill(0);

  return (
    <Container>
      <TitleContainer>
        <TableBarIcon style={{ width: 24, height: 24 }} />
        <Title>Tables</Title>
      </TitleContainer>
      <TablesContainer>
        {tablesArray.map((_, index) => {
          const tableUsers = tablesInfo[index];

          return (
            <TableContainer key={index}>
              <TableLabel>n°{index + 1}</TableLabel>
              <DotsContainer>
                {tableUsers.userIds.map((userId) => (
                  <Dot key={userId} />
                ))}
              </DotsContainer>
            </TableContainer>
          );
        })}
      </TablesContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  max-width: 100px;
`;

const TableLabel = styled.div`
  font-size: 10px;
`;

const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
`;

const Dot = styled.div`
  width: 8px;
  aspect-ratio: 1;
  border-radius: 10px;
  background-color: #15bc15;
`;
