import styled from "styled-components";
import {
  AnswerOption,
  GetTableAnswerQuery,
  QuestionFragment,
  QuestionState,
  QuestionType,
  useGetTableAnswerQuery,
  useOnAnswerAddedSubscription,
} from "../data/generated";
import { Title } from "./Title";
import { useEffect, useState } from "react";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";
import { useUser } from "../contexts/UserContext";
import { RevealedOverlay } from "./RevealedOverlay";
import { useTranslate } from "../utils/translate";
import { useApolloClient } from "@apollo/client";
import { GET_TABLE_ANSWER_QUERY } from "../data/queries/answer";

export const Question = ({
  question,
  onSubmit,
}: {
  question: QuestionFragment;
  onSubmit: (answers: string[]) => Promise<boolean>;
}) => {
  const { t } = useTranslation();
  const translate = useTranslate();
  const {
    _id: questionId,
    availableAt,
    correctAnswers,
    wrongAnswers,
    imageUrl,
    question: questionTitle,
    revealedImageUrl,
    type,
    state,
  } = question;

  const { tableNumber } = useUser();

  const client = useApolloClient();

  const [answerIds, setAnswerIds] = useState<string[]>([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasTimeElapsed, setHasTimeElapsed] = useState(false);
  const [sortedAnswerOptions, setSortedAnswerOptions] = useState<
    AnswerOption[]
  >([]);

  const { data } = useGetTableAnswerQuery({
    variables: { questionId, tableNumber },
    onCompleted(data) {
      if (data.tableAnswer) {
        setAnswerIds(data.tableAnswer.answerIds);
      }
    },
  });

  useOnAnswerAddedSubscription({
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) {
        return;
      }

      const { answerAdded } = data;

      if (
        tableNumber === answerAdded.tableNumber &&
        questionId === answerAdded.questionId
      ) {
        client.writeQuery<GetTableAnswerQuery>({
          query: GET_TABLE_ANSWER_QUERY,
          variables: { questionId, tableNumber },
          data: { tableAnswer: answerAdded },
        });
        setAnswerIds(answerAdded.answerIds);
      }
    },
  });

  useEffect(() => {
    setSortedAnswerOptions(
      [...correctAnswers, ...wrongAnswers].sort(() => Math.random() - 0.5)
    );
  }, []);

  useEffect(() => {
    if (availableAt) {
      const questionAvailableAt = new Date(availableAt);
      setTimeout(
        () => setHasTimeElapsed(true),
        questionAvailableAt.getTime() - Date.now() + 15000
      );
    }
  }, [availableAt]);

  const canAnswer =
    state === QuestionState.Available &&
    !hasSubmitted &&
    !data?.tableAnswer &&
    hasTimeElapsed;

  function selectAnswer(answerId: string) {
    if (hasSubmitted) {
      return;
    }

    if (type !== QuestionType.MultipleAnswers) {
      setAnswerIds([answerId]);
      return;
    }

    if (answerIds.includes(answerId)) {
      setAnswerIds(
        answerIds.filter((existingAnswer) => existingAnswer !== answerId)
      );
      return;
    }

    setAnswerIds([...answerIds, answerId]);
  }

  async function submitAnswer() {
    setHasSubmitted(true);
    await onSubmit(answerIds);
  }

  return (
    <div>
      <img
        src={imageUrl}
        style={{ width: "100%", aspectRatio: 1.5, objectFit: "cover" }}
      />
      <ContentContainer>
        <Title>{translate(questionTitle)}</Title>
        <AnswerOptions>
          {sortedAnswerOptions.map((answerOption, index) => (
            <AnswerOptionLabel
              key={index}
              isDisabled={!canAnswer}
              isSelected={answerIds.includes(answerOption._id)}
              isValidated={
                answerIds.includes(answerOption._id) && !!data?.tableAnswer
              }
              onClick={() =>
                canAnswer ? selectAnswer(answerOption._id) : undefined
              }
            >
              {translate(answerOption.label)}
            </AnswerOptionLabel>
          ))}
        </AnswerOptions>
        <Button
          label={hasTimeElapsed ? t("validate") : t("discuss")}
          onClick={submitAnswer}
          isDisabled={!canAnswer || hasSubmitted || !answerIds.length}
        />
      </ContentContainer>
      {question.state === QuestionState.Revealed ? (
        <RevealedOverlay
          tableAnswer={data?.tableAnswer}
          revealedImageUrl={revealedImageUrl}
          correctAnswers={correctAnswers}
        />
      ) : null}
    </div>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
`;

const AnswerOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AnswerOptionLabel = styled.div<{
  isDisabled: boolean;
  isSelected: boolean;
  isValidated: boolean;
}>`
  border-radius: 5px;
  background-color: ${({ isSelected, isValidated }) =>
    isValidated ? "#ffae00" : isSelected ? "#868686" : "#eeeeee"};
  color: ${({ isSelected, isValidated }) =>
    isValidated || isSelected ? "white" : "black"};
  padding: 10px;
  text-align: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;
