import { gql } from "@apollo/client";

export const ANSWER_FRAGMENT = gql`
  fragment Answer on Answer {
    _id
    answerIds
    questionId
    score
    tableNumber
  }
`;
