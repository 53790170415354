import { useTranslation } from "react-i18next";

export function useTranslate() {
  const { i18n } = useTranslation();

  function translate(label: { en: string; fr: string }) {
    switch (i18n.language) {
      case "fr":
        return label["fr"];
      case "en":
        return label["en"];
      default:
        return label["fr"];
    }
  }

  return translate;
}
