import styled from "styled-components";
import {
  GetQuestionsInfoQuery,
  QuestionFragment,
  QuestionState,
  useArchiveQuestionMutation,
  useGetQuestionsInfoQuery,
  useOnAnswerAddedSubscription,
  useRevealQuestionMutation,
  useStartQuestionMutation,
} from "../data/generated";
import { Title } from "./Title";
import { Button } from "./Button";
import { NUMBER_OF_TABLES } from "../constants/tables";
import { useApolloClient } from "@apollo/client";
import QuizIcon from "@mui/icons-material/Quiz";
import { GET_QUESTIONS_INFO_QUERY } from "../data/queries/question";

export const QuestionsInfo = () => {
  const client = useApolloClient();

  const { data } = useGetQuestionsInfoQuery();

  const [startQuestionMutation] = useStartQuestionMutation();
  const [revealQuestionMutation] = useRevealQuestionMutation();
  const [archiveQuestionMutation] = useArchiveQuestionMutation();

  useOnAnswerAddedSubscription({
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) {
        return;
      }

      const { answerAdded } = data;

      const cachedQuery = client.readQuery<GetQuestionsInfoQuery>({
        query: GET_QUESTIONS_INFO_QUERY,
      });

      if (!cachedQuery) {
        return;
      }

      const updatedQuestionsInfo = cachedQuery.questionsInfo.map((question) => {
        if (question._id !== answerAdded.questionId) {
          return question;
        }
        return { ...question, answers: [...question.answers, answerAdded] };
      });

      client.writeQuery<GetQuestionsInfoQuery>({
        query: GET_QUESTIONS_INFO_QUERY,
        data: { questionsInfo: updatedQuestionsInfo },
      });
    },
  });

  if (!data) {
    return null;
  }

  const { questionsInfo } = data;

  const numberOfArchivedQuestions = questionsInfo.filter(
    (question) => QuestionState.Archived === question.state
  ).length;

  function reveal(question: QuestionFragment) {
    revealQuestionMutation({ variables: { questionId: question._id } });
  }

  function start(question: QuestionFragment) {
    startQuestionMutation({ variables: { questionId: question._id } });
  }

  function archive(question: QuestionFragment) {
    archiveQuestionMutation({ variables: { questionId: question._id } });
  }

  const tablesArray = new Array(NUMBER_OF_TABLES).fill(0);

  return (
    <Container>
      <TitleContainer>
        <QuizIcon style={{ width: 24, height: 24 }} />
        <Title>Questions</Title>
      </TitleContainer>
      <QuestionsContainer>
        {questionsInfo.map((question, index) => {
          return (
            <QuestionContainer key={index}>
              <QuestionLabel>Question n°{index + 1}</QuestionLabel>
              <div>{question.question.fr}</div>
              <div>{question.question.en}</div>
              <div>
                <b>Correct</b>
              </div>
              <div>
                <span>
                  {question.correctAnswers
                    .map((answer) => answer.label.fr)
                    .join("/")}
                </span>
              </div>
              <div>
                <b>Incorrect</b>
              </div>
              <div>
                <span>
                  {question.wrongAnswers
                    .map((answer) => answer.label.fr)
                    .join("/")}
                </span>
              </div>
              <div>
                <img
                  src={question.imageUrl}
                  width="100%"
                  style={{ aspectRatio: 4 / 3, objectFit: "cover" }}
                />
              </div>
              <div>{question.state}</div>
              {question.state === QuestionState.Hidden &&
              numberOfArchivedQuestions === index ? (
                <Button
                  label="Commencer la question"
                  onClick={() => start(question)}
                />
              ) : null}
              {question.state === QuestionState.Available ? (
                <Button
                  label="Révéler la réponse"
                  onClick={() => reveal(question)}
                />
              ) : null}
              {question.state === QuestionState.Revealed ? (
                <Button
                  label="Archiver la question"
                  onClick={() => archive(question)}
                />
              ) : null}
              <b>Missing answers from:</b>
              <Tables>
                {tablesArray
                  .map((_, tableNumber) => tableNumber)
                  .filter((tableNumber) =>
                    question.answers.every(
                      (answer) => answer.tableNumber !== tableNumber
                    )
                  )
                  .map((tableNumber) => (
                    <Table key={tableNumber}>{tableNumber + 1}</Table>
                  ))}
              </Tables>
            </QuestionContainer>
          );
        })}
      </QuestionsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  max-width: 200px;
  font-size: 12px;
`;

const QuestionLabel = styled.div`
  font-size: 14px;
  font-weight: 800;
`;

const Tables = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
`;

const Table = styled.div`
  background-color: #dedede;
  border-radius: 3px;
  padding: 3px;
  width: 16px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
