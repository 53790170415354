import styled from "styled-components";
import { Title } from "./Title";
import { Button } from "./Button";
import { useResetGameMutation, useStartGameMutation } from "../data/generated";
import SettingsIcon from "@mui/icons-material/Settings";

export const AdminActions = () => {
  const [startGameMutation] = useStartGameMutation();
  const [resetGameMutation] = useResetGameMutation();

  function startGame() {
    startGameMutation();
  }

  async function resetGame() {
    if (confirm("Êtes-vous sûr ?")) {
      await resetGameMutation();
    }
  }

  return (
    <Container>
      <TitleContainer>
        <SettingsIcon style={{ width: 24, height: 24 }} />
        <Title>Actions</Title>
      </TitleContainer>
      <ActionsContainer>
        <Button label="Commencer la partie" onClick={startGame} />
        <Button
          label="Réinitialiser la partie"
          onClick={resetGame}
          isDangerous
        />
      </ActionsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
