import { createContext, useContext } from "react";

export type User = { deviceId: string; tableNumber: number };

export const UserContext = createContext<{
  user: User | null;
  setUser: (value: User | null) => void;
}>({
  user: null,
  setUser: () => undefined,
});

export const useUserContext = () => useContext(UserContext);

export const useUser = () => {
  const { user } = useUserContext();

  if (!user) {
    throw new Error();
  }

  return user;
};
