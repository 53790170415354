import "./i18n/config";
import "./App.css";

import { useEffect, useState } from "react";
import { User, UserContext } from "./contexts/UserContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "./data/config.connections";
import { GameWrapper } from "./components/GameWrapper";

function App() {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      setUser(JSON.parse(userFromStorage));
    }
    setHasLoaded(true);
  }, []);

  function updateUser(user: User | null) {
    if (user === null) {
      localStorage.removeItem("user");
      setUser(null);
      return;
    }
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }

  if (!hasLoaded) {
    return <div>Chargement</div>;
  }

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={{ user, setUser: updateUser }}>
        <div className="App">
          <GameWrapper />
        </div>
      </UserContext.Provider>
    </ApolloProvider>
  );
}

export default App;
