import { useTranslation } from "react-i18next";
import { supportedLngs } from "./config";
import styled from "styled-components";

export default function LocaleSwitcher() {
  const { i18n } = useTranslation();

  return (
    <LocaleSwitcherContainer>
      <select
        value={i18n.resolvedLanguage}
        onChange={(e) => {
          console.log("selecting", e.target.value);
          i18n.changeLanguage(e.target.value);
        }}
      >
        {Object.entries(supportedLngs).map(([code, name]) => (
          <option value={code} key={code}>
            {name}
          </option>
        ))}
      </select>
    </LocaleSwitcherContainer>
  );
}

const LocaleSwitcherContainer = styled.div`
  padding: 10px;
`;
