import styled from "styled-components";
import {
  GetRankingQuery,
  useGetRankingQuery,
  useOnRankingUpdatedSubscription,
} from "../data/generated";
import { Title } from "./Title";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { GET_RANKING_QUERY } from "../data/queries/answer";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

export const Ranking = () => {
  const client = useApolloClient();
  const { data } = useGetRankingQuery({ fetchPolicy: "cache-and-network" });

  const { t } = useTranslation();

  useOnRankingUpdatedSubscription({
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) {
        return;
      }

      const { rankingUpdated } = data;

      client.writeQuery<GetRankingQuery>({
        query: GET_RANKING_QUERY,
        data: { ranking: rankingUpdated },
      });
    },
  });

  if (!data) {
    return null;
  }

  const { ranking } = data;

  const tableScores = ranking.map((score, tableNumber) => ({
    tableNumber,
    score,
  }));

  const sortedScores = tableScores.sort(
    (tableScoreA, tableScoreB) => tableScoreB.score - tableScoreA.score
  );

  return (
    <Container>
      <TitleContainer>
        <FormatListNumberedIcon style={{ width: 24, height: 24 }} />
        <Title>{t("ranking")}</Title>
      </TitleContainer>
      <RankingContainer>
        {sortedScores.map(({ tableNumber, score }, rankIndex) => (
          <Row key={tableNumber}>
            <Rank>
              {rankIndex === 0
                ? "🥇"
                : rankIndex === 1
                  ? "🥈"
                  : rankIndex === 2
                    ? "🥉"
                    : rankIndex + 1}
            </Rank>
            <TableNumber isBold={rankIndex < 3}>
              Table n°{tableNumber + 1}
            </TableNumber>
            <Score isBold={rankIndex < 3}>{score}</Score>
          </Row>
        ))}
      </RankingContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const RankingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Rank = styled.div`
  width: 70px;
  text-align: center;
`;

const Score = styled.div<{ isBold: boolean }>`
  display: flex;
  justify-content: center;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  width: 50px;
  text-align: center;
`;

const TableNumber = styled.div<{ isBold: boolean }>`
  display: flex;
  align-items: center;
  width: 110px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
`;
