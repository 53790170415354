import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { Title } from "./Title";
import styled from "styled-components";
import {
  AnswerFragment,
  GetAnswersQuery,
  useGetAnswersQuery,
  useGetQuestionsInfoQuery,
  useOnAnswerAddedSubscription,
} from "../data/generated";
import { NUMBER_OF_TABLES } from "../constants/tables";
import { GET_ANSWERS_QUERY } from "../data/queries/answer";
import { useApolloClient } from "@apollo/client";

function getTotalScore(answers: AnswerFragment[]) {
  return answers.reduce((acc, answer) => acc + answer.score, 0);
}

export const RankingInfo = () => {
  const client = useApolloClient();

  const { data: answersData } = useGetAnswersQuery();
  const { data: questionsData } = useGetQuestionsInfoQuery();

  useOnAnswerAddedSubscription({
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) {
        return;
      }

      const { answerAdded } = data;

      const cachedQuery = client.readQuery<GetAnswersQuery>({
        query: GET_ANSWERS_QUERY,
      });

      if (!cachedQuery) {
        return;
      }

      const updatedAnswersInfo = cachedQuery.answers.some(
        (answer) => answer._id === answerAdded._id
      )
        ? answers
        : [...answers, answerAdded];

      client.writeQuery<GetAnswersQuery>({
        query: GET_ANSWERS_QUERY,
        data: { answers: updatedAnswersInfo },
      });
    },
  });

  if (!answersData || !questionsData) {
    return null;
  }

  const { answers } = answersData;
  const { questionsInfo } = questionsData;

  const tableNumbers = new Array(NUMBER_OF_TABLES)
    .fill(0)
    .map((_, index) => index);

  const tableData = tableNumbers.map((tableNumber) => ({
    tableNumber,
    tableAnswers: answers.filter(
      (answer) => answer.tableNumber === tableNumber
    ),
  }));

  const sortedData = [...tableData].sort(
    (rowA, rowB) =>
      getTotalScore(rowB.tableAnswers) - getTotalScore(rowA.tableAnswers)
  );

  const highestScores = questionsInfo.map((question) => {
    const questionAnswers = answers.filter(
      (answer) => answer.questionId === question._id
    );

    const highestScore = Math.max(
      ...questionAnswers.flatMap((answer) => answer.score)
    );

    return { questionId: question._id, highestScore };
  });

  return (
    <Container>
      <TitleContainer>
        <FormatListNumberedIcon style={{ width: 24, height: 24 }} />
        <Title>Classement</Title>
      </TitleContainer>
      <RankingContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>Table</TableHeader>
              {questionsInfo.map((_, questionNumber) => (
                <TableHeader key={questionNumber}>
                  Q{questionNumber + 1}
                </TableHeader>
              ))}
              <TableHeader>Score total</TableHeader>
            </tr>
          </thead>
          <tbody>
            {sortedData.map(({ tableNumber, tableAnswers }) => {
              const totalScore = getTotalScore(tableAnswers);

              return (
                <tr
                  key={tableNumber}
                  style={{
                    backgroundColor: tableNumber % 2 ? "#f4f4f4" : "white",
                  }}
                >
                  <TableD>Table n°{tableNumber + 1}</TableD>
                  {questionsInfo.map((question, questionNumber) => {
                    const tableAnswer = tableAnswers.find(
                      (answer) => answer.questionId === question._id
                    );

                    return (
                      <TableD
                        key={questionNumber}
                        isBold={
                          tableAnswer?.score ===
                          highestScores.find(
                            (highScore) => highScore.questionId === question._id
                          )?.highestScore
                        }
                      >
                        {tableAnswer?.score}
                      </TableD>
                    );
                  })}
                  <TableD>{totalScore}</TableD>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </RankingContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const RankingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  overflow-x: scroll;
`;

const Table = styled.table`
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const TableD = styled.td<{ isBold?: boolean }>`
  border: 1px solid lightgrey;
  border-collapse: collapse;
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
`;
